import React, { Component } from 'react'
// import html2canvas from 'html2canvas'

import '../css/invitation-cards.scss'

import Layout from '../layouts/home'
import SEO from '../components/seo'
import * as CardThemes from '../components/invitationCards';
import DownloadShare from '../components/downloadShare';

export default class InvitationCardsPage extends Component {

  constructor(props) {
    super(props)

    const themes = Object.keys(CardThemes);

    this.state = {
      themes: themes,
      selectedTheme: themes[0],
      showDownloadShareModal: false
    }

  }

  sendImage = () => {
    // html2canvas(document.querySelector("#cardMain")).then(canvas => {
    //   const link = document.createElement('a');
    //   link.download = 'cn-card.png';
    //   link.href = canvas.toDataURL('image/jpeg')
    //   link.click();
    // });
    this.setState({
      showDownloadShareModal: true
    })
  }

  selectTheme = (t) => {
    this.setState({ selectedTheme: t })
  }

  themeList = () => {
    return this.state.themes.map(t => {
      return <button
        type="button"
        class={ `list-group-item list-group-item-action ${t === this.state.selectedTheme && 'active'}` }
        onClick={_ => { this.selectTheme(t) } }
      >{t}</button>
    })
  }

  render() {

    const ThemeComponent = CardThemes[this.state.selectedTheme]

    return <Layout>
      <SEO
        title="Create your own invitation card"
        keywords={[]}
      />
      <section class="reserve-block light-bg">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Invitation Card Designer</h5>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container design-container">
          <div class="row">
            <div class="col-md-3">
              <div class="list-group list-group-flush">
                { this.themeList() }
              </div>
            </div>
            <div class="col-md-9">
              <div class="card-container">
                <ThemeComponent download={this.sendImage} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <DownloadShare show={this.state.showDownloadShareModal} />
    </Layout>
  }
}

