import React, { Component } from 'react'

import './style.scss'
import Image from './bg.png';
import CardText from '../cardText';

const width = "500px";

const textData = () => [
  { x: 50, y: 120, width: "300px", size: "46px", text: "We're", styled: true },
  { x: 150, y: 150, width: "300px", size: "46px", text: "getting", styled: true },
  { x: 120, y: 180, width: "300px", size: "60px", text: "Married", styled: true },

  { x: 10, y: 410, width: "180px", size: "40px", text: "Suman", style: { textAlign: 'right' }},
  { x: 10, y: 460, width: "180px", size: "24px", text: "Shrestha", style: { textAlign: 'right' }},
  { x: 188, y: 410, width: "100px", size: "60px", text: "&" },
  { x: 310, y: 410, width: "180px", size: "40px", text: "Gita", style: { textAlign: 'left' }},
  { x: 310, y: 460, width: "180px", size: "24px", text: "Pradhan", style: { textAlign: 'left' }},

  { x: 10, y: 560, width: "180px", size: "34px", text: "10:00 AM", style: { textAlign: 'right' }},

  { x: 188, y: 540, width: "100px", size: "20px", text: "March" },
  { x: 188, y: 555, width: "100px", size: "30px", text: "24" },
  { x: 188, y: 590, width: "100px", size: "24px", text: "2019" },

  { x: 310, y: 560, width: "180px", size: "16px", text: "Maxis Banquet", style: { textAlign: 'left' }},
  { x: 310, y: 580, width: "180px", size: "16px", text: "Sanepa, Lalitpur", style: { textAlign: 'left' }},
]

export default class PurpleDream extends Component {
  state = { textData: textData()}

  remove = (i) => {
    const newTextData = this.state.textData.slice();
    newTextData[i].hidden = true;
    newTextData[i].blahhhhh = true;
    this.setState({
      textData: newTextData
    })
  }

  reset = () => {
    this.setState({
      textData: textData()
    })
  }

  renderTexts = () => {
    return this.state.textData.map((t, i) => <CardText{...t} idx={i} remove={this.remove.bind(i)} />)
  }

  render() {
    return <div>
      <div style={{ marginBottom: '10px' }}>
        <button class="btn btn-danger btn-sm" onClick={this.reset}>	↻ Reset</button>
        &nbsp;
        <button class="btn btn-warning btn-sm" onClick={this.props.download}>↯ Download Card</button>
      </div>
      <div class="card-preview floral" id="cardMain">
        <div
          class="card-preview-inner"
          style={
            {
              backgroundImage: `url(${Image})`,
              backgroundRepeat: 'repeat'
            }
          }
        >
        </div>
        <div class="card-content">
          { this.renderTexts() }
        </div>
      </div>
    </div>
  }
}
