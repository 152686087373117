import React, { Component } from 'react'

import './style.scss'
import Image from './bg.jpg';
import CardText from '../cardText';

const width = "500px";

const textData = () => [
  { x: 100, y: 160, text: "Mrs. Rojina and Mr. Pawan Shakya" },
  { x: 50, y: 190, width, size: "14px", text: "Coordially invite you to grace the wedding of their son" },
  { x: 50, y: 220, width, size: "40px", text: "Suman Shakya" },
  { x: 50, y: 270, width, size: "18px", text: "With" },
  { x: 50, y: 285, width, size: "40px", text: "Gita Pradhan" },
  { x: 50, y: 350, width, size: "14px", text: "(Daughter of Mrs. Chanda and Mr. Prabhu Pradhan)" },
  { x: 50, y: 440, width, size: "14px", color: "#e6e6e6", text: "On Monday, July 10, 2019<br />At Nepa Banquet<br />Naya Bazar, Kathmandu" },
  { x: 50, y: 520, width, size: "14px", color: "#e6e6e6", text: "Programs" },
  { x: 50, y: 540, width, size: "14px", color: "#e6e6e6", text: "Sagun: 12:00 PM<br />Dinner: 6:00 AM Onwards" },
]


export default class TraditionalWedding extends Component {
  state = { textData: textData()}

  remove = (i) => {
    const newTextData = this.state.textData.slice();
    newTextData[i].hidden = true;
    this.setState({
      textData: newTextData
    })
  }

  reset = () => {
    this.setState({
      textData: textData()
    })
  }

  renderTexts = () => {
    return this.state.textData.map((t, i) => <CardText{...t} idx={i} remove={this.remove.bind(i)} />)
  }

  render() {
    return  <div>
      <div style={{ marginBottom: '10px' }}>
        <button class="btn btn-danger btn-sm" onClick={this.reset}>	↻ Reset</button>
        &nbsp;
        <button class="btn btn-warning btn-sm" onClick={this.props.download}>↯ Download Card</button>
      </div>
      <div class="card-preview traditional" id="cardMain">
        <div
          class="card-preview-inner"
          style={
            {
              backgroundImage: `url(${Image})`,
              backgroundRepeat: 'repeat'
            }
          }
        >
        </div>
        <div class="card-content">
          { this.renderTexts() }
        </div>
      </div>
    </div>
  }
}
