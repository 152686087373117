import React, { Component } from 'react'

import './style.scss'
import Image from './bg.png';
import CardText from '../cardText';

const width = "500px";

const textData = () => [
  { x: 0, y: 110, width, text: "Mrs. Rojina and Mr. Pawan Shakya", size: '20px'},
  { x: 0, y: 150, width, size: "16px", text: "Coordially invite you to grace the wedding of their son" },
  { x: 0, y: 190, width, size: "36px", text: "Suman Shakya", styled: true },
  { x: 0, y: 240, width, size: "18px", text: "With", styled: true },
  { x: 0, y: 260, width, size: "36px", text: "Gita Pradhan", styled: true },
  { x: 0, y: 330, width, size: "16px", text: "(Daughter of Mrs. Chanda and Mr. Prabhu Pradhan)" },
  { x: 0, y: 400, width, size: "16px", color: "#db6b00", text: "On Monday, July 10, 2019<br />At Nepa Banquet<br />Naya Bazar, Kathmandu" },
  { x: 0, y: 490, width, size: "16px", color: "#db6b00", text: "Programs" },
  { x: 0, y: 510, width, size: "16px", color: "#db6b00", text: "Sagun: 12:00 PM<br />Dinner: 6:00 AM Onwards" },
]

export default class PurpleDream extends Component {
  state = { textData: textData()}

  remove = (i) => {
    const newTextData = this.state.textData.slice();
    newTextData[i].hidden = true;
    newTextData[i].blahhhhh = true;
    this.setState({
      textData: newTextData
    })
  }

  reset = () => {
    this.setState({
      textData: textData()
    })
  }

  renderTexts = () => {
    return this.state.textData.map((t, i) => <CardText{...t} idx={i} remove={this.remove.bind(i)} />)
  }

  render() {
    return <div>
      <div style={{ marginBottom: '10px' }}>
        <button class="btn btn-danger btn-sm" onClick={this.reset}>	↻ Reset</button>
        &nbsp;
        <button class="btn btn-warning btn-sm" onClick={this.props.download}>↯ Download Card</button>
      </div>
      <div class="card-preview yellow-paper" id="cardMain">
        <div
          class="card-preview-inner"
          style={
            {
              backgroundImage: `url(${Image})`,
              backgroundRepeat: 'repeat'
            }
          }
        >
        </div>
        <div class="card-content">
          { this.renderTexts() }
        </div>
      </div>
    </div>
  }
}
