import React, { Component } from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';

class DownloadShare extends Component {
  render() {
    return (
      <div>
        <div class="modal" tabIndex="-1" role="dialog" style={{ display: this.props.show ? "block" : "none" }}>
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <ModalHeader>Sharing is Caring</ModalHeader>
              <ModalBody>
                Love our work?
                <br />
                Why no give us a like and share?
              </ModalBody>
            </div>
          </div>
        </div>
        { this.props.show && <div class="modal-backdrop fade show"></div> }
      </div>
    );
  }
}

export default DownloadShare;
