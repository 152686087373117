import React, { Component } from 'react'

import './cardText.scss'

export default class CardText extends Component {

  remove = () => {
    this.props.remove(this.props.idx)
  }

  render() {
    const { props } = this;

    return props.hidden ? null : <div
      className={ `card-content-editable ${props.styled && 'styled'}` }
      style={{
          position: 'absolute',
          width: props.width || '400px',
          top: `${props.y}px`,
          left: `${props.x}px`,
          textAlign: 'center',
          fontSize: props.size || '16px',
          color: props.color,
          ...props.style || {}
        }}>
      <div className="card-content-editable--wrap">
        <div
          className="card-content-editable--input"
          dangerouslySetInnerHTML={{__html: props.text}}
          contentEditable="true" />
        <div class="card-content-editable--remove" onClick={this.remove}>✖</div>
      </div>
    </div>
  }
}
