import React, { Component } from 'react'

import './style.scss'
import Image from './bg.png';
import CardText from '../cardText';

const width = "500px";

const textData = () => [
  { x: 100, y: 250, text: "We are glad to invite you", size: '20px'},
  { x: 50, y: 280, width, size: "26px", text: "We are getting married" },
  { x: 65, y: 340, width: "180px", size: "40px", text: "Suman", style: { textAlign: 'right' }},
  { x: 65, y: 390, width: "180px", size: "24px", text: "Shrestha", style: { textAlign: 'right' }},
  { x: 243, y: 340, width: "100px", size: "60px", text: "&" },
  { x: 360, y: 340, width: "180px", size: "40px", text: "Gita", style: { textAlign: 'left' }},
  { x: 360, y: 390, width: "180px", size: "24px", text: "Pradhan", style: { textAlign: 'left' }},
  { x: 50, y: 450, width, size: "24px", text: "Baisakh 5th, 2080" },
  { x: 50, y: 510, width, size: "18px", text: "Nepa Banquet, Naya Bazaar" },
  { x: 50, y: 540, width, size: "14px", text: "Reception start at 2:00 PM" },
  { x: 50, y: 560, width, size: "14px", text: "Dinner and Party 5:00 PM Onwards" },
]

export default class PurpleDream extends Component {
  state = { textData: textData()}

  remove = (i) => {
    const newTextData = this.state.textData.slice();
    newTextData[i].hidden = true;
    newTextData[i].blahhhhh = true;
    this.setState({
      textData: newTextData
    })
  }

  reset = () => {
    this.setState({
      textData: textData()
    })
  }

  renderTexts = () => {
    return this.state.textData.map((t, i) => <CardText{...t} idx={i} remove={this.remove.bind(i)} />)
  }

  render() {
    return <div>
      <div style={{ marginBottom: '10px' }}>
        <button class="btn btn-danger btn-sm" onClick={this.reset}>	↻ Reset</button>
        &nbsp;
        <button class="btn btn-warning btn-sm" onClick={this.props.download}>↯ Download Card</button>
      </div>
      <div class="card-preview simple" id="cardMain">
        <div
          class="card-preview-inner"
          style={
            {
              backgroundImage: `url(${Image})`,
              backgroundRepeat: 'repeat'
            }
          }
        >
        </div>
        <div class="card-content">
          { this.renderTexts() }
        </div>
      </div>
    </div>
  }
}
